import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Button, Container, Flex } from '@chakra-ui/react';
import {
	CHANNELS,
	defaultCampaign,
} from 'src/lib/schemas/campaign/defaultCampaign';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	MultiSelectCreateableInputHook,
	MultiSelectValuesHook,
	PromoCodesInputHook,
	StringInputHook,
	TextareaInputHook,
} from '../common/form';
import { ProductFormModal } from '../commerce/products';
import ConfigFormModal from '../config/ConfigFormModal';
import { AppInputConfigContext, CampaignContext } from 'src/contexts';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import useFetch from 'src/hooks/useFetch';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';
import useCatalogKinds from 'src/hooks/useCatalogKinds';
import {
	createAccountConfigOptions,
	getAccountConfigOptions,
} from 'src/services/account';
import {
	ECampaignPromotedType,
	ICatalog,
	ICatalogForm,
	IChannel,
	IChannelGroup,
	IDesignDirection,
} from 'src/lib/schemas';
import { IAccountConfigOption, IOption } from 'src/lib/schemas/misc';
import { transformCampaignToForm } from 'src/lib/utils';
import { toastError } from 'src/services/toast';
import { ChannelType } from './channels/ChannelType';
import MarketingChannelsForm from './channels/MarketingChannelsForm';
import {
	ICampaignForm,
	CampaignFormSchema,
} from 'src/lib/schemas/campaign/form';
import useAutoSaveCampaign from 'src/hooks/v2/useAutoSaveCampaign';
import DesignLockModal from './design-directions/LockModal';
import {
	createOrUpdateCampaign,
	genrateCampaignCreatives,
	getCampaign,
} from 'src/services/campaign';
import UserContext from 'src/contexts/UserContext';
import { debounce } from 'lodash';
import MultiSelectCreatProducteableInputHook from '../common/form/MultiSelectCreateableInput/MultiSelectCreateableProductInputHook';
import SelectTemplateModal from './design-directions/SelectTemplateModal';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import SelectedTemplatePreview from './SelectTemplatePreview';
import { useDebouncedFunction } from 'src/hooks/v2/useDebouncedFunction';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';

interface IQueryState {
	option: IOption;
	type: ECampaignPromotedType;
	selectedChannel?: string;
}

interface CampaignFormProps {
	group?: string;
	availableChannels: IChannel[];
	onCampaignTitleChange: (title: string) => void;
	onCampaignSubmit: () => void;
	onTemplateGalleryOpen: () => void;
	onMetaOptionsChange: (options: IOption[]) => void;
	availableChannelGroups: IChannelGroup[] | null;
	setOpenSections: React.Dispatch<React.SetStateAction<boolean[]>>;
	designDirectionsRef: React.RefObject<HTMLDivElement>;
	selectedPlacements: string[];
	setSelectedPlacements: React.Dispatch<React.SetStateAction<string[]>>;
	designDirections: IDesignDirection[];
	setDesignDirections: React.Dispatch<React.SetStateAction<IDesignDirection[]>>;
	setCreatives: React.Dispatch<React.SetStateAction<ICreative[]>>;
	onRegenerateDesignDirections: () => void;
	onChannelsChange: React.Dispatch<React.SetStateAction<string[]>>;
}

const CampaignForm: FC<CampaignFormProps> = ({
	group,
	availableChannels,
	onCampaignTitleChange,
	onCampaignSubmit,
	onTemplateGalleryOpen,
	onMetaOptionsChange,
	designDirectionsRef,
	availableChannelGroups,
	selectedPlacements,
	setSelectedPlacements,
	designDirections,
	setDesignDirections,
	setCreatives,
	onRegenerateDesignDirections,
	onChannelsChange,
}) => {
	const formProductToggle = useToggleWithPayload<Partial<ICatalog>>();
	const formToneToggle = useToggleWithPayload<IAccountConfigOption>();
	const formAudienceToggle = useToggleWithPayload<IAccountConfigOption>();
	const lockDDToggle = useToggleWithPayload<IDesignDirection[]>([]);
	const [dirtyPlacements, setDirtyPlacements] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
	const [isChannelUpdating, setIsChannelUpdating] = useState(false);
	const [isPlacementUpdating, setIsPlacementUpdating] = useState(false);
	const { inputConfig, loadingInputs, loadedInputs } = useContext(
		AppInputConfigContext,
	);
	const {
		selectedTemplateIds,
		setSelectedTemplateIds,
		setSelectedTemplate,
		setSelectTemplateModalOpen,
		templates,
		templatesByScope,
		isSelectTemplateModalOpen,
	} = useContext(TemplateContext);
	const { account } = useContext(UserContext);
	const { fetchConfig: fetchTones, createConfig: createTone } =
		useAccountConfigOptions('Tone');
	const { fetchConfig: fetchAudiences, createConfig: createAudience } =
		useAccountConfigOptions('Audience');

	const { catalogKinds } = useCatalogKinds();
	const [isPromotedObjectLoaded, setIsPromotedObjectLoaded] = useState(false);
	const {
		campaign,
		id: campaignId,
		setCampaign,
		completeStep,
		setOpenSections,
	} = useContext(CampaignContext);
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const sectionEndRef = useRef<HTMLDivElement>(null);
	const isNewCampaign = campaignId && campaignId === 'new';

	const locationState = location.state as IQueryState;

	const formMethods = useForm<ICampaignForm>({
		resolver: zodResolver(CampaignFormSchema),
		defaultValues: defaultCampaign as unknown as ICampaignForm,
	});
	const { reset, setValue } = formMethods;
	const isFormDirty = formMethods.formState.isDirty;
	const title = formMethods.watch('title');
	const watchedDescription = formMethods.watch('description');
	const promotedObjectType = formMethods.watch('promotedObject.type');
	const destination = formMethods.watch('destination');
	const watchedChannels = formMethods.watch('channels') ?? {};
	const watchedPlacements = formMethods.watch('placements') ?? [];
	const watchedPromotedObjectValue = formMethods.watch('promotedObject.value');
	const watchedDestination = formMethods.watch('destination');
	const watchedGoals = formMethods.watch('goal') ?? [];
	const watchedAudience = formMethods.watch('audience');
	const watchedTones = formMethods.watch('tone');

	const watchedPromotion = formMethods.watch('promotion');
	const watchedBudgetTotal = formMethods.watch('budget.total');

	const handleCancel = () => {
		setOpenSections([false, true, false, false]);
		if (designDirectionsRef.current) {
			const elementPosition =
				designDirectionsRef.current.getBoundingClientRect().top +
				window.scrollY;
			const offsetPosition = elementPosition - 400;
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth',
			});
		}
	};

	const autosave = useAutoSaveCampaign(
		setDirtyPlacements,
		setDesignDirections,
		setCreatives,
	);

	useEffect(() => {
		if (!campaign?.id) {
			autosave.onFieldsChange(undefined);
		}
	}, [campaign?.id]);

	useEffect(() => {
		if (!formMethods.watch('channels') && campaign?.channels) {
			setValue('channels', campaign.channels, { shouldDirty: false });
		}
	}, [campaign?.channels, setValue]);

	useEffect(() => {
		if (isNewCampaign && availableChannels) {
			reset(defaultCampaign as unknown as ICampaignForm);
			setValue('channels', CHANNELS);

			const selectedPlacementIds = availableChannels.flatMap(
				(channel) => channel.placements?.map((placement) => placement.id) || [],
			);
			setValue('placements', selectedPlacementIds, { shouldDirty: false });
			setValue('promotedObject.type', ECampaignPromotedType.landingPage, {
				shouldDirty: false,
			});
		}
	}, [campaignId, availableChannels, reset, setValue]);

	useEffect(() => {
		if (watchedChannels) {
			onChannelsChange(watchedChannels);
		}
	}, [watchedChannels]);

	const selectedTemplate = useMemo(() => {
		if (selectedTemplateIds.length === 0) return null;
		const id = selectedTemplateIds[0];
		return (
			templates.find((t) => t.id === id) ||
			templatesByScope.find((t) => t.id === id)
		);
	}, [selectedTemplateIds, templates, templatesByScope]);

	useEffect(() => {
		onCampaignTitleChange(title);
	}, [title]);

	useEffect(() => {
		if (loadingInputs['Tone'] || loadedInputs['Tone']) return;
		fetchTones();
	}, [loadingInputs['Tone'], loadedInputs['Tone']]);

	useEffect(() => {
		if (loadingInputs['Audience'] || loadedInputs['Audience']) return;
		fetchAudiences();
	}, [loadingInputs['Audience'], loadedInputs['Audience']]);

	useEffect(() => {
		if (title) {
			formMethods.clearErrors('title');
		}
		if (watchedDescription) {
			formMethods.clearErrors('description');
		}
		if (watchedAudience?.length) {
			formMethods.clearErrors('audience');
		}
		if (watchedTones?.length) {
			formMethods.clearErrors('tone');
		}
		if (
			watchedPromotedObjectValue?.length ||
			promotedObjectType === ECampaignPromotedType.landingPage
		) {
			formMethods.clearErrors('promotedObject.value');
		}
		if (destination) {
			formMethods.clearErrors('destination');
		}
		if (watchedPlacements?.length > 0) {
			formMethods.clearErrors('placements');
			formMethods.clearErrors('channels');
		}
	}, [
		title,
		watchedDescription,
		watchedAudience,
		watchedTones,
		destination,
		watchedPromotedObjectValue,
		promotedObjectType,
		watchedPlacements.length,
	]);

	useEffect(() => {
		const updateCampaignChannels = async () => {
			let selectedChannels = [];
			if (locationState?.selectedChannel) {
				switch (locationState.selectedChannel) {
					case 'all':
						selectedChannels = CHANNELS;
						break;
					case 'paid':
						selectedChannels = ['facebookPaid', 'instagramPaid', 'google'];
						break;
					case 'direct':
						selectedChannels = ['sms', 'pns', 'email'];
						break;
					case 'facebook':
						selectedChannels = ['facebook', 'facebookPaid'];
						break;
					case 'instagram':
						selectedChannels = ['instagram', 'instagramPaid'];
						break;
					default:
						selectedChannels = [locationState.selectedChannel];
						break;
				}
				try {
					const newCampaign = await createOrUpdateCampaign(
						{ channels: selectedChannels },
						'new',
					);
					navigate(`/projects/campaigns/${newCampaign.id}`, {
						replace: true,
						state: { selectedChannels },
					});
				} catch (error) {
					console.error('Error updating campaign channels:', error);
				}
			}
		};

		updateCampaignChannels();
	}, [locationState]);

	const debouncedCreateOrUpdateCampaign = useDebouncedFunction(
		createOrUpdateCampaign,
		300,
	);

	const handleChannelChange = (channelId: string, channelType: ChannelType) => {
		setDirtyPlacements(true);
		if (isChannelUpdating) return;
		setIsChannelUpdating(true);

		const selectedChannel = availableChannels.find(
			(channel) => channel.id === channelId,
		);

		if (!selectedChannel || !selectedChannel.placements) {
			setIsChannelUpdating(false);
			return;
		}

		const selectedPlacementIds = selectedChannel.placements
			.filter((placement) => placement.type === channelType)
			.map((item) => item.id);

		setSelectedPlacements((prevSelectedPlacements) => {
			const allSelected = selectedPlacementIds.every((id) =>
				prevSelectedPlacements.includes(id),
			);

			const updatedPlacements = allSelected
				? prevSelectedPlacements.filter(
						(id) => !selectedPlacementIds.includes(id),
				  )
				: Array.from(
						new Set([...prevSelectedPlacements, ...selectedPlacementIds]),
				  );

			setValue('placements', updatedPlacements);

			onChannelsChange((prevChannels) => {
				const updatedChannels = allSelected
					? prevChannels.filter((id) => id !== channelId)
					: Array.from(new Set([...prevChannels, channelId]));

				if (updatedChannels.length > 0 && updatedPlacements.length > 0) {
					const debouncedResult = debouncedCreateOrUpdateCampaign(
						{
							channels: updatedChannels,
						},
						campaignId ?? 'new',
					);

					if (debouncedResult) {
						debouncedResult.finally(() => setDirtyPlacements(false));
					} else {
						setDirtyPlacements(false);
					}
				} else {
					setDirtyPlacements(false);
				}

				setValue('channels', updatedChannels);

				return updatedChannels;
			});

			setIsChannelUpdating(false);
			return updatedPlacements;
		});
	};

	const handlePlacementChange = (placementId: string) => {
		if (isPlacementUpdating) return;
		setIsPlacementUpdating(true);

		setSelectedPlacements((prevSelectedPlacements) => {
			const updatedPlacements = prevSelectedPlacements.includes(placementId)
				? prevSelectedPlacements.filter((id) => id !== placementId)
				: [...prevSelectedPlacements, placementId];

			setValue('placements', updatedPlacements);

			const updatedChannels = availableChannels
				.filter(
					(channel) =>
						channel.placements?.some((placement) =>
							updatedPlacements.includes(placement.id),
						),
				)
				.map((channel) => channel.id);

			if (updatedChannels.length > 0 && updatedPlacements.length > 0) {
				const debouncedResult = debouncedCreateOrUpdateCampaign(
					{
						placements: updatedPlacements,
					},
					campaignId ?? 'new',
				);

				if (debouncedResult) {
					debouncedResult.finally(() => setDirtyPlacements(false));
				} else {
					setDirtyPlacements(false);
				}
			} else {
				setDirtyPlacements(false);
			}
			setValue('channels', updatedChannels);

			setIsPlacementUpdating(false);
			return updatedPlacements;
		});
	};

	const handleRefreshDesignDirections = async () => {
		if (!campaignId) return;
		try {
			const response = await getCampaign(campaignId);
			setDesignDirections(response.designDirections ?? []);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		if (account && promotedObjectType === ECampaignPromotedType.landingPage) {
			formMethods.setValue(
				'destination',
				account.websiteLink ?? 'www.example.com',
			);
		}
	}, [promotedObjectType]);

	const handleMetaValueChange = (
		value: IOption['value'][] | IOption[],
		generateByAI?: boolean,
	) => {
		if (typeof value[0] === 'string') return;

		if (generateByAI) {
			formMethods.setValue(
				'promotedObject.type',
				ECampaignPromotedType.landingPage,
			);
		} else {
			formMethods.setValue(
				'promotedObject.type',
				ECampaignPromotedType.catalogs,
			);
		}

		formMethods.setValue('destination', value[0]?.url ?? '');
	};

	useEffect(() => {
		if (!dirtyPlacements && isSubmitting) {
			onTemplateGalleryOpen();
			setIsSubmitting(false);
		}
	}, [dirtyPlacements, isSubmitting]);

	const moveToNextStep = () => {
		setOpenSections([false, true, false, false]);
		completeStep(0);
		setTimeout(() => sectionEndRef.current?.scrollIntoView(), 300);
	};

	const handleRegenerateDesignDirections = () => {
		moveToNextStep();
		onRegenerateDesignDirections();
	};

	const handleFocusOnFirstError = useCallback(() => {
		const errorFields = Object.keys(formMethods.formState.errors);
		if (errorFields.length > 0) {
			formMethods.setFocus(errorFields[0] as any);
		}
	}, [formMethods]);

	const submitForm = async () => {
		const isFormValid = await formMethods.trigger();
		if (!isFormValid) {
			handleFocusOnFirstError();
			return;
		}

		moveToNextStep();
		if (dirtyPlacements) {
			setIsSubmitting(true);
			return;
		}
		if (
			!designDirections.length ||
			designDirections.filter((designDirection) => designDirection.locked)
				.length < 3
		) {
			if (selectedTemplate) {
				await onCampaignSubmit();
			} else {
				onTemplateGalleryOpen();
			}
		}
	};

	const {
		data: products,
		refetch: refetchProducts,
		isLoading: isProductsLoading,
	} = useFetch<ICatalog[]>(() => getAccountConfigOptions('catalogs'));

	const handleCreateProduct = async (payload: ICatalogForm) => {
		try {
			const createdCatalog = await createAccountConfigOptions(
				'catalogs',
				payload,
			);
			refetchProducts();
			formMethods.setValue('promotedObject.value', [
				{
					value: createdCatalog._id,
					label: payload.name,
				},
			]);
			formMethods.setValue('destination', payload.url);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleCreateNewMeta = (val: string) => {
		const defaultInitialValues = {
			kind: 'service',
			name: val,
			url: '',
			details: {},
			category: undefined,
			tags: [],
		};

		switch (promotedObjectType) {
			case ECampaignPromotedType.catalogs:
				formProductToggle.onOpen(defaultInitialValues);
				break;
			default:
				break;
		}
	};

	const getMetaOptions = () => {
		if (promotedObjectType === ECampaignPromotedType.catalogs) {
			return (products || []).map((product) => ({
				label: product.name,
				value: product.id,
				url: product.url,
			}));
		}
	};

	const handleCreateToneOption = async (name: string) => {
		if (!name.trim()) {
			formToneToggle.onOpen();
			return;
		}

		await handleCreateTone({ name });
	};

	const handleCreateTone = async (payload: IAccountConfigOption) => {
		try {
			const newTone = await createTone(payload);
			formMethods.setValue('tone', [...(watchedTones || []), newTone.id]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleCreateAudienceOption = async (name: string) => {
		if (!name.trim()) {
			formAudienceToggle.onOpen();
			return;
		}

		await handleCreateAudience({ name });
	};

	const handleCreateAudience = async (payload: IAccountConfigOption) => {
		try {
			const newAudience = await createAudience(payload);
			formMethods.setValue('audience', [
				...(watchedAudience || []),
				newAudience.id,
			]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const isMetaOptionsLoading = isProductsLoading;
	const CAMPAIGN_META_OPTIONS = useMemo(
		() => getMetaOptions(),
		[isMetaOptionsLoading, promotedObjectType],
	);

	useEffect(() => {
		CAMPAIGN_META_OPTIONS?.length && onMetaOptionsChange(CAMPAIGN_META_OPTIONS);
	}, [CAMPAIGN_META_OPTIONS]);

	const queryStatus = searchParams.get('status');

	useEffect(() => {
		formMethods.setValue('budget.total', '0', { shouldDirty: false });

		return () => {
			setSelectedTemplate(null);
			setSelectedTemplateIds([]);
		};
	}, []);

	useEffect(() => {
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isPromotedObjectLoaded &&
			CAMPAIGN_META_OPTIONS?.length
		) {
			const { promotedObject, destination } = transformCampaignToForm(
				campaign,
				CAMPAIGN_META_OPTIONS,
			);
			if (promotedObject.type === promotedObjectType) {
				setIsPromotedObjectLoaded(true);
				formMethods.setValue('promotedObject.value', promotedObject.value);
				formMethods.setValue('destination', destination);
			}
		}
	}, [campaign?.id, CAMPAIGN_META_OPTIONS]);

	useEffect(() => {
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isCampaignLoaded
		) {
			setIsCampaignLoaded(true);
			const {
				title,
				description,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
				placements,
			} = transformCampaignToForm(campaign, CAMPAIGN_META_OPTIONS ?? []);

			formMethods.reset({
				title,
				description,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
				placements,
			});
		}
	}, [campaign, queryStatus, isMetaOptionsLoading]);

	const debouncedHandleFieldChanges = useCallback(
		debounce(() => {
			handleFieldChanges();
		}, 300),
		[],
	);

	useEffect(() => {
		const isOldCampaignLoaded = Boolean(campaign && isCampaignLoaded);
		const shouldSaveDelta = isNewCampaign || isOldCampaignLoaded;
		if (!shouldSaveDelta || !group || !isFormDirty || isNewCampaign) return;
		debouncedHandleFieldChanges();
	}, [
		promotedObjectType,
		watchedPromotedObjectValue,
		watchedDestination,
		watchedAudience,
		watchedTones,
		watchedPromotion,
		watchedGoals.length,
		group,
		debouncedHandleFieldChanges,
	]);

	const handleFieldChanges = async () => {
		autosave.onFieldsChange({ ...formMethods.getValues(), group });
	};

	const setFieldValue = (field: any, value: string) => {
		formMethods.setValue(field, value, { shouldDirty: true });
		debouncedHandleFieldChanges();
	};

	const handleTitleTabKeyPress = (e: any) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			formMethods.setFocus('description');
		}
	};

	return (
		<Container w="full" maxW="full" pb={5} m={0}>
			<FormProvider {...formMethods}>
				<form>
					<Flex direction="column" gap={10}>
						{selectedTemplate && <SelectedTemplatePreview />}
						<StringInputHook
							name="title"
							label="Campaign name"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabel="This is the name that you will use to identify this later.  e.g: Christmas campaign"
							placeholder="Enter campaign name"
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							inputProps={{
								onChange: debouncedHandleFieldChanges,
								onKeyDown: (e: any) => handleTitleTabKeyPress(e),
							}}
							direction="row"
							required
						/>
						<TextareaInputHook
							name="description"
							label="Describe your campaign"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabel="Describe what you are promoting, to whom, when, and what you want to achieve. The more you put here, the better the results."
							placeholder="What are you promoting and why? To whom? When? What are you trying to achieve? The more detail the better."
							textareaProps={{ onChange: debouncedHandleFieldChanges }}
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							withAssistant
							setFieldValue={setFieldValue}
							required
							direction="row"
						/>
						<MultiSelectCreatProducteableInputHook
							name="promotedObject.value"
							label="Do you have an image for what you are promoting?"
							requirementsLabel="Select or create a product or a service from your catalog"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							isLoading={isMetaOptionsLoading}
							placeholder="Select/search"
							options={CAMPAIGN_META_OPTIONS || []}
							onValueChangeCallback={handleMetaValueChange}
							onCreateOption={handleCreateNewMeta}
							isMulti={false}
							promotedObjectType={promotedObjectType}
							valueAsObject
							isClearable
							required
							direction="row"
						/>
						<Flex direction="column">
							<MultiSelectCreateableInputHook
								name="audience"
								label="Who are your customers?"
								formLabelProps={{ fontSize: '16px' }}
								requirementsLabel="Select or create the characteristics which describe your customers"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								placeholder="Select/search"
								options={inputConfig['Audience'] ?? []}
								isLoading={loadingInputs['Audience']}
								onCreateOption={handleCreateAudienceOption}
								isMulti
								required
								direction="row"
							/>
							<Flex alignSelf="flex-end">
								<MultiSelectValuesHook
									name="audience"
									title="Target Audience"
									options={inputConfig['Audience'] ?? []}
								/>
							</Flex>
						</Flex>
						<Flex direction="column">
							<MultiSelectCreateableInputHook
								name="tone"
								label="What tone of voice do you want to use?"
								formLabelProps={{ fontSize: '16px' }}
								requirementsLabel="Select or create the characteristics which describe how to communicate with your customers"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								placeholder="Select/search"
								options={inputConfig['Tone'] ?? []}
								isLoading={loadingInputs['Tone']}
								onCreateOption={handleCreateToneOption}
								isMulti
								required
								direction="row"
							/>
							<Flex alignSelf="flex-end" maxW="50%">
								<MultiSelectValuesHook
									name="tone"
									options={inputConfig['Tone'] ?? []}
								/>
							</Flex>
						</Flex>

						<Box>
							<PromoCodesInputHook
								name="promotion"
								label="Will you offer a promotional codes? (Optional)"
								requirementsLabel="Please enter a promotional discount code your customers can use"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								formLabelProps={{ mb: 0, fontSize: '16px' }}
								valuesContainerProps={{ mt: 0 }}
								direction="row"
								withRadioOptions={true}
							/>
						</Box>
						<MarketingChannelsForm
							availableChannels={availableChannels}
							availableChannelGroups={availableChannelGroups || []}
							requirementsLabel="Select all the channels where you want to promote your business and we will generate everything for that specific channel."
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							selectedPlacements={selectedPlacements}
							onChannelChange={handleChannelChange}
							onPlacementChange={handlePlacementChange}
						/>
						<Box display="none" textAlign="center">
							<Button
								id="campaign-config-form-submit"
								colorScheme="secondary"
								fontWeight="medium"
								onClick={submitForm}
							></Button>
						</Box>

						<Box alignSelf="flex-end">
							<Button
								variant="orangeSolid"
								type="button"
								id="campaign-config-form-submit-with-lock-modal"
								onClick={async () => {
									await formMethods.trigger();
									if (
										Object.keys(formMethods.formState.errors).length === 0 &&
										designDirections?.length &&
										designDirections?.some((dd) => dd.status === 'GENERATED')
									) {
										campaignId &&
											genrateCampaignCreatives(campaignId, '', true);
										lockDDToggle.onOpen(
											designDirections.filter(
												(dd) => dd.status === 'GENERATED',
											),
										);
										return;
									}

									await submitForm();
									setSelectedTemplateIds([]);
								}}
							>
								Generate Designs
							</Button>
						</Box>
					</Flex>
				</form>
			</FormProvider>

			<Box ref={sectionEndRef}></Box>

			<ProductFormModal
				isOpen={formProductToggle.isOpen}
				catalogKinds={catalogKinds}
				onClose={formProductToggle.onClose}
				onSubmit={handleCreateProduct}
				title="What are you promoting?"
				initialValues={formProductToggle.payload}
			/>
			<ConfigFormModal
				isOpen={formToneToggle.isOpen}
				onClose={formToneToggle.onClose}
				onSubmit={handleCreateTone}
				initialValues={formToneToggle.payload}
				title="Tone"
			/>
			<ConfigFormModal
				isOpen={formAudienceToggle.isOpen}
				onClose={formAudienceToggle.onClose}
				onSubmit={handleCreateAudience}
				initialValues={formAudienceToggle.payload}
				title="Audience"
			/>
			<DesignLockModal
				isOpen={lockDDToggle.isOpen}
				onCancel={handleCancel}
				onClose={lockDDToggle.onClose}
				onSubmit={handleRegenerateDesignDirections}
				designDirections={lockDDToggle.payload}
				setDesignDirections={setDesignDirections}
			/>
			{isSelectTemplateModalOpen && (
				<SelectTemplateModal
					isOpen={isSelectTemplateModalOpen}
					onClose={() => setSelectTemplateModalOpen(false)}
					handleRefreshDesignDirections={handleRefreshDesignDirections}
					source="campaignForm"
					onSubmit={onCampaignSubmit}
				/>
			)}
		</Container>
	);
};

export default CampaignForm;
