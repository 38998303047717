import { useState, useEffect, useMemo, useContext, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { CampaignContext } from 'src/contexts';
import {
	getCampaignDelta,
	validateInitialPayload,
} from 'src/components/campaign/utils/delta';
import { ICampaign, ICampaignForm } from 'src/lib/schemas';
import { createOrUpdateCampaign, getCampaign } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { transformPartialFormToCampaign } from 'src/components/campaigns/utils/transform';
import {
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { isEmpty, isEqual, omit } from 'lodash';

const CAMPAIGN_FIELDS_OMIT = [
	'account',
	'status',
	'brief',
	'creatives',
	'schedule',
	'lastUpdatedBy',
	'updatedAt',
	'createdAt',
];

const useAutoSaveCampaign = (
	setDirtyPlacements: Dispatch<React.SetStateAction<boolean>>,
	setDesignDirections: Dispatch<React.SetStateAction<IDesignDirection[]>>,
	setCreatives: Dispatch<React.SetStateAction<ICreative[]>>,
) => {
	const {
		campaign,
		id: campaignId,
		setCampaign,
		isLaunching,
	} = useContext(CampaignContext);

	const [changedFields, setChangedFields] = useState<
		Partial<ICampaign> | undefined
	>(undefined);
	const [isSaving, setIsSaving] = useState(false);
	const [isReading, setIsReading] = useState(false);
	const [pendingChangesQueue, setPendingChangesQueue] = useState<
		Partial<ICampaign>[]
	>([]);

	const navigate = useNavigate();

	const shouldRead = useMemo(() => {
		return (
			!isReading &&
			!isSaving &&
			!changedFields &&
			pendingChangesQueue.length === 0 &&
			campaignId !== 'new'
		);
	}, [isReading, isSaving, changedFields, pendingChangesQueue, campaignId]);

	const cleanedCampaign = useMemo(() => {
		return omit(campaign, CAMPAIGN_FIELDS_OMIT);
	}, [campaign]);

	useEffect(() => {
		if (!isSaving && !isEmpty(changedFields)) {
			//@ts-ignore
			setCampaign((prevState) => ({ ...prevState, ...changedFields }));
			handleUpdateCampaign();
		}
	}, [changedFields, isSaving]);

	useEffect(() => {
		let intervalId: NodeJS.Timeout | undefined;

		if (shouldRead) {
			const fetchCampaignData = async () => {
				try {
					setIsReading(true);
					const response = await getCampaign(campaignId!);
					setDesignDirections(response.designDirections || []);
					setCreatives(response.creatives || []);
				} catch (error) {
					console.error('Error fetching campaign data:', error);
				} finally {
					setIsReading(false);
				}
			};

			intervalId = setInterval(() => {
				fetchCampaignData();
			}, 600);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [shouldRead, isSaving, changedFields, pendingChangesQueue]);

	const removeMatchingChangedField = () => {
		setPendingChangesQueue((prevQueue) => {
			const index = prevQueue.findIndex((item) => isEqual(item, changedFields));

			if (index === -1) {
				return prevQueue;
			}

			const newQueue = [...prevQueue];
			newQueue.splice(index, 1);

			return newQueue;
		});
	};

	const handleUpdateCampaign = async () => {
		if (isSaving || !changedFields) return;
		setIsSaving(true);

		try {
			const delta = getCampaignDelta(cleanedCampaign, changedFields);
			const payload = omit(delta, ['id', 'status']);

			// Removes all undefined properties from the payload object.
			const cleanedPayload = (
				Object.keys(payload) as Array<keyof typeof payload>
			).reduce(
				(acc, key) => {
					const value = payload[key];
					if (value !== undefined) {
						//@ts-ignore
						acc[key] = value;
					}
					return acc;
				},
				{} as Partial<Omit<ICampaign, 'id' | 'status'>>,
			);

			const isEmptyPayload = isEmpty(cleanedPayload);
			const isInvalidPayload = validateInitialPayload({
				...changedFields,
				group: undefined,
			});

			if (isEmptyPayload || isInvalidPayload) {
				setIsSaving(false);
				return;
			}

			const response = await createOrUpdateCampaign(
				payload,
				campaignId ?? 'new',
			);

			response.designDirections &&
				setDesignDirections(response.designDirections);

			if (campaignId === 'new') {
				navigate(`/projects/campaigns/${response.id}?status=draft`, {
					replace: true,
				});
			}

			if (payload.placements) {
				setDirtyPlacements(false);
			}
		} catch (error: any) {
			if (error.response) {
				const isTarget = error.response.data.message.includes('target');
				!isTarget && toastError(error);
			} else {
				console.error(error);
			}
		} finally {
			setIsSaving(false);
			setIsReading(false);
			removeMatchingChangedField();

			if (pendingChangesQueue.length > 0) {
				const nextChanges = pendingChangesQueue[0];
				setChangedFields(nextChanges);
			} else {
				setChangedFields(undefined);
			}
		}
	};

	const handleFieldsChange = (formData: Partial<ICampaignForm> | undefined) => {
		if (isLaunching) return;

		const transformedFormValues = formData
			? transformPartialFormToCampaign(formData, campaign)
			: undefined;

		if (isSaving && transformedFormValues) {
			setPendingChangesQueue((prevQueue) => [
				...prevQueue,
				transformedFormValues,
			]);
		} else {
			setChangedFields(transformedFormValues);
		}
	};

	return { onFieldsChange: handleFieldsChange };
};

export default useAutoSaveCampaign;
